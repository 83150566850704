import Swal from "sweetalert2";

Turbo.setConfirmMethod((message, element) => {
  const locale = document.body.dataset.locale || 'fr';

  // Not yet possible to have custom title: https://github.com/hotwired/turbo/issues/811
  // const title = this.element.dataset.title;

  return new Promise((resolve) => {
    Swal.fire({
      title: locale === 'fr' ? 'Êtes-vous sûr ?' : 'Are you sure?',
      html: message || '',
      showCancelButton: true,
      confirmButtonText: locale === 'fr' ? 'Oui' : 'Yes',
      cancelButtonText: locale === 'fr' ? 'Non' : 'No',
    }).then((result) => {
      if (result.value) return resolve(true);
      resolve(false);
    });
  });
});
