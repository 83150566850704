import {Controller} from "@hotwired/stimulus"
import PlacesAutocomplete from "stimulus-places-autocomplete"

// Connects to data-controller="places"
export default class extends PlacesAutocomplete {
  static targets = ["streetAndStreetNumber"];

  connect() {
    this.countryValue = ["ca"];
    document.addEventListener("google-maps-callback", this.initAutocomplete.bind(this));
    super.connect();
  }

  disconnect() {
    document.removeEventListener("google-maps-callback", this.initAutocomplete.bind(this));
    super.disconnect();
  }

  initAutocomplete() {
    if (this.hasStreetAndStreetNumberTarget) {
      this.autocomplete = new google.maps.places.Autocomplete(this.streetAndStreetNumberTarget, this.autocompleteOptions)
      this.autocomplete.addListener('place_changed', this.placeChanged)
    } else {
      super.initAutocomplete();
    }
  }

  setAddressComponents(address) {
    super.setAddressComponents(address);

    if (this.hasStreetAndStreetNumberTarget) {
      this.streetAndStreetNumberTarget.value = [(address.street_number || ''), (address.route || '')].join(" ");
    }
    if (this.hasCountryTarget) {
      this.countryTarget.value = this.countryValue[0].toUpperCase();
    }
  }
}
