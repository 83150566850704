export const parseHTMLElement = (html) => {
  const newNode = document.createElement('template');

  newNode.innerHTML = html;

  return newNode.content.firstChild;
}

export const parseHTMLDocument = (html) => {
  return (new DOMParser()).parseFromString(html, 'text/html');
}

export const resetValues = (container) => {
  container.querySelectorAll('input:not([type="radio"]):not([type="hidden"])').forEach((input) => {
    input.value = input.dataset.inputmaskFormat ? "" : null;
  });
  container.querySelectorAll('input[type=radio]:checked').forEach((input) => {
    input.checked = false;
  });
}
