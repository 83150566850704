import { Controller } from "@hotwired/stimulus"
import {parseHTMLElement} from "../website/javascript/utils/html";
import createFileList from "create-file-list/dist/create-file-list.mjs";

// Connects to data-controller="file-upload"

export default class extends Controller {
    connect() {
        this.loadFileUploads();
    }
    loadFileUploads() {
        if(this.element.classList.contains("cocoon-file-input")){
            this.multipleUpload();
        }else{
            this.singleUpload();
        }
    }

    singleUpload(){
        const container = this.element.parentNode;

        const uploadButton = container.querySelector('.fileuploadbutton');
        const fileLabel = container.querySelector('.fileuploadedlabel');
        const deleteFileLabel = container.querySelector('.clearFile');
        const removeCheckbox = container.querySelector('.removeCheckbox');
        const hiddenInput = container.querySelector('.hidden');

        const emptyText = document.body.dataset.locale === "fr" ? "Aucun fichier sélectionné" : "No file selected";

        if (container.classList.contains("js-image-upload")) {
            container.addEventListener('click', (e) => {
                this.element.click();
            });

            this.element.addEventListener('change', (e) => {
                container.querySelector('img').src = URL.createObjectURL(e.target.files[0]);

                if (container.querySelector('img').classList.contains("d-none")) {
                    container.querySelector('img').classList.remove("d-none");
                    container.querySelector('div').classList.add("d-none");
                }
            });
        }

        if (fileLabel) {
            this.element.addEventListener('change', (e) => {
                fileLabel.textContent = e.target.files[0].name
                deleteFileLabel?.classList.remove("d-none");
                removeCheckbox.checked = false;
            });
        }

        if (uploadButton) {
            uploadButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.element.click();
            });
        }

        if (deleteFileLabel) {
            deleteFileLabel.addEventListener('click', (e) => {
                e.preventDefault();

                deleteFileLabel.classList.add("d-none");
                this.element.value = null;
                hiddenInput.value = null;
                fileLabel.textContent = emptyText;
                removeCheckbox.checked = true;
            });

            if (fileLabel.innerText === emptyText) {
                deleteFileLabel.classList.add("d-none");
            }
        }
    }

    multipleUpload(){
        const uploadButton = this.element.parentNode.querySelector('.fileuploadbutton');

        if (uploadButton) {
            uploadButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.element.click();
            });
        }

        this.element.addEventListener('change', (e) => {
            const cocoonInsert = this.element.parentNode.querySelector('.form-data').querySelector('.nested-file:first-of-type');

            if (e.target.files) {
                let cocoonInsertId = cocoonInsert.querySelector('input').name.split('[');
                cocoonInsertId = parseInt(cocoonInsertId[cocoonInsertId.length - 2].slice(0, -1));

                const html = cocoonInsert.outerHTML;

                Array.from(e.target.files).forEach((file, i) => {
                    const newNode = parseHTMLElement(html.replaceAll(cocoonInsertId, cocoonInsertId + i + 1));

                    cocoonInsert.after(newNode);

                    const input = newNode.querySelector('input[type="file"]');
                    newNode.classList.remove('d-none');
                    input.files = createFileList(file);

                    newNode.querySelector('.filename').textContent = file.name;
                });
            }
            cocoonInsert.remove();

            this.element.value = null;
        });
    }
}