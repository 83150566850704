import {Controller} from "@hotwired/stimulus"

import TinyMCE from '../libs/tinymce';

// Connects to data-controller="tinymce"
export default class extends Controller {
  connect() {
    super.connect();

    this.editors = [];

    TinyMCE.init(Object.assign({target: this.element, license_key: 'gpl'}, this.defaults))
      .then((editors) => this.editors = editors);
  }

  initialize() {
    this.defaults = {
      forced_root_block: 'div',
      toolbar_sticky: true, // Menu bar is fixed when you scroll inside text editor
      skin: false, // Fix 404
      content_css: false, // Fix 404
      menubar: true,
      link_default_target: "_blank", // Anchor option defaults to 'New Window'
      plugins: 'link lists emoticons fullscreen preview table code wordcount image',
      toolbar: [
        'styleselect | bold italic underline strikethrough superscript emoticons hr forecolor backcolor | blockquote numlist bullist link | alignleft aligncenter alignright | table',
        'undo redo | fullscreen preview code help image'
      ],
      mobile: {
        toolbar: [
          'styleselect | bold italic underline strikethrough superscript',
          'blockquote numlist bullist link | alignleft aligncenter alignright | table',
          'undo redo | fullscreen preview code help'
        ]
      },
    }
  }

  disconnect() {
    if (!this.preview && this.editors.length > 0) {
      this.editors.forEach((editor) => {
        editor.remove();
      });
    }
  }
}