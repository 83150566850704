import {Controller} from "@hotwired/stimulus"
import _ from "lodash";

import {formatDecimal, formatDigit, formatDigitCurrency, parseNumber} from "../../../website/javascript/utils/math";
import {resetValues} from "../../../website/javascript/utils/html"
import {Question1Base} from "../base/question1_base_controller";

// Connects to data-controller="questions--produced-in-quebec--question1"
export default class extends Question1Base {
  static targets = ["rawMaterials", "workforces"]

  connect() {
    this.computeWorkforcesChildCount = _.debounce(this.computeWorkforcesChildCount, 10);
    this.computeRawMaterialsChildCount = _.debounce(this.computeRawMaterialsChildCount, 10);

    this.element.addEventListener("cocoon:after-remove", () => {
      this.element.dispatchEvent(new Event("js-blue-box-update"));
    });

    this.listenBlueBoxChange();
  }

  rawMaterialsTargetConnected(target) {
    target.querySelector('.remove_fields').addEventListener('click', () => this.computeRawMaterialsChildCount());
    this.computeRawMaterialsChildCount();
    this.addSymbol(target);
    this.addDelimiters(target);
    this.computeRawMaterialUnitPrice(target);
    this.computeProductUnitPrice(target);
    this.computeTotalCostQuebec(target);
    this.computePercentageRawMaterialQuebec(target);
    this.computeBlueBox(target);
    this.duplicateRawMaterials(target);
  }

  workforcesTargetConnected(target) {
    target.querySelector('.remove_fields').addEventListener('click', () => this.computeWorkforcesChildCount());
    this.computeWorkforcesChildCount();
    this.addSymbol(target);
    this.computeWorkforceUnitPrice(target);
    this.computeWorkforceUnitPriceQuebec(target);
    this.computePercentageWorkforceQuebec(target);
    this.computeBlueBox(target);
    this.duplicateWorkforce(target);
  }

  addSymbol(target) {
    const inputs = target.querySelectorAll('.js-currency');
    const formatNumbers = (input) => {
      if (input.value !== "") {
        input.value = (formatDigitCurrency(parseNumber(input.value)));
      }
    }

    inputs.forEach((i) => {
      i.addEventListener('change', (e) => formatNumbers(e.target));
      formatNumbers(i);
    });
  }

  addDelimiters(target) {
    const inputs = target.querySelectorAll('.js-decimal');
    const formatNumbers = (input) => {
      if (input.value !== "") {
        input.value = (formatDecimal(parseNumber(input.value)));
      }
    }

    inputs.forEach((i) => {
      i.addEventListener('change', (e) => formatNumbers(e.target));
      formatNumbers(i);
    });
  }

  /** BEGIN: Raw materials automatic calculations */

  duplicateRawMaterials(target){
    const duplicateButton = target.querySelector('.js-duplicate');
    const nameInput = target.querySelector('input[name*="name"]');
    const natureInput = target.querySelector('select[name*="nature"]');
    const descriptionInput = target.querySelector('input[name*="description"]');
    const supplierNameInput = target.querySelector('input[name*="supplier_name"]');
    const namePersonResponsibleInput = target.querySelector('input[name*="name_person_responsible_sale"]');
    const lastNamePersonResponsibleInput = target.querySelector('input[name*="last_name_person_responsible_sale"]');
    const supplierAddressInput = target.querySelector('input[name*="supplier_address"]');
    const supplierAddress2Input = target.querySelector('input[name*="supplier_address2"]');
    const supplierCityInput = target.querySelector('input[name*="supplier_city"]');
    const supplierCountryInput = target.querySelector('select[name*="supplier_country"]');
    const supplierPostalCodeInput = target.querySelector('input[name*="supplier_postal_code"]');
    const supplierEmailInput = target.querySelector('input[name*="supplier_email"]');
    const supplierPhoneInput = target.querySelector('input[name*="supplier_phone"]');



    duplicateButton.onclick = function(e) {
      e.preventDefault();

      const addAssociation = document.getElementById("add_raw_materials");
      addAssociation.click();

      const lastChild = [...document.querySelectorAll('.raw_materials')].pop();
      const placeOriginInput = target.querySelector('input[name*="place_origin_quebec"]:checked');

      lastChild.querySelector('input[name*="name"]').value = nameInput.value;
      lastChild.querySelector('select[name*="nature"]').value = natureInput.options[natureInput.selectedIndex].value;
      lastChild.querySelector('input[name*="description"]').value = descriptionInput.value;
      lastChild.querySelector('input[name*="place_origin_quebec"][value="' + placeOriginInput?.value + '"]').checked = true;
      lastChild.querySelector('input[name*="supplier_name"]').value = supplierNameInput.value;
      lastChild.querySelector('input[name*="name_person_responsible_sale"]').value = namePersonResponsibleInput.value;
      lastChild.querySelector('input[name*="last_name_person_responsible_sale"]').value = lastNamePersonResponsibleInput.value;
      lastChild.querySelector('input[name*="supplier_address"]').value = supplierAddressInput.value;
      lastChild.querySelector('input[name*="supplier_address2"]').value = supplierAddress2Input.value;
      lastChild.querySelector('input[name*="supplier_city"]').value = supplierCityInput.value;
      lastChild.querySelector('select[name*="supplier_country"]').value = supplierCountryInput.options[supplierCountryInput.selectedIndex].value;
      lastChild.querySelector('input[name*="supplier_postal_code"]').value = supplierPostalCodeInput.value;
      lastChild.querySelector('input[name*="supplier_email"]').value = supplierEmailInput.value;
      lastChild.querySelector('input[name*="supplier_phone"]').value = supplierPhoneInput.value;
      lastChild.scrollIntoView(true);
    }
  }
  computeRawMaterialUnitPrice(target) {
    const unitPriceInput = target.querySelector('.js-raw-material-unit-price');
    const quantityInput = target.querySelector('input[name*="total_quantity_purchased"]');
    const totalCostInput = target.querySelector('input[name*="total_cost"]');

    const updateUnitPrice = () => {
      const value = parseNumber(totalCostInput.value) / parseNumber(quantityInput.value);

      if (!isNaN(value) && value !== Infinity) {
        unitPriceInput.value = formatDigitCurrency(value);
      } else {
        unitPriceInput.value = formatDigitCurrency(0);
      }

      unitPriceInput.dispatchEvent(new Event('change'));
    }

    quantityInput.addEventListener('change', updateUnitPrice);
    totalCostInput.addEventListener('change', updateUnitPrice);

    updateUnitPrice();
  }

  computeProductUnitPrice(target) {
    const unitPriceInput = target.querySelector('.js-raw-material-unit-price');
    const quantityInput = target.querySelector('input[name*="quantity_required_produce"]');
    const totalCostInput = target.querySelector('.js-product-unit-price');

    const updateUnitPrice = () => {
      const value = parseNumber(unitPriceInput.value) * parseNumber(quantityInput.value);

      if (!isNaN(value) && value !== Infinity) {
        totalCostInput.value = formatDigitCurrency(value);
      } else {
        totalCostInput.value = formatDigitCurrency(0);
      }

      totalCostInput.dispatchEvent(new Event('change'));
    }

    unitPriceInput.addEventListener('change', updateUnitPrice);
    quantityInput.addEventListener('change', updateUnitPrice);

    updateUnitPrice();
  }

  computeTotalCostQuebec(target) {
    const quebecInputs = target.querySelectorAll('input[name*="place_origin_quebec"]');
    const totalCostInput = target.querySelector('.js-product-unit-price');
    const totalCostInputQuebec = target.querySelector('.js-product-unit-price-quebec');

    const updateUnitPrice = () => {
      const selected = target.querySelector('input[name*="place_origin_quebec"]:checked')?.value;

      if (selected === "true") {
        totalCostInputQuebec.value = totalCostInput.value;
      } else {
        totalCostInputQuebec.value = formatDigitCurrency(0);
      }
      totalCostInputQuebec.dispatchEvent(new Event('change'));
    }

    quebecInputs.forEach((r) => r.addEventListener('change', updateUnitPrice));
    totalCostInput.addEventListener('change', updateUnitPrice);

    updateUnitPrice();
  }

  computePercentageRawMaterialQuebec(target) {
    const totalCostInputQuebec = target.querySelector('.js-product-unit-price-quebec');
    const totalCostProduct = target.querySelector('.js-product-unit-price');
    const percentageInput = target.querySelector('.js-raw-material-quebec-percentage');

    const updatePercentage = () => {
      const value = Math.round(parseNumber(totalCostInputQuebec.value) / parseNumber(totalCostProduct.value) * 100);

      if (!isNaN(value) && value !== Infinity) {
        percentageInput.value = `${value} %`;
      } else {
        percentageInput.value = '0 %';
      }

      percentageInput.dispatchEvent(new Event('change'));
    }

    totalCostInputQuebec.addEventListener('change', updatePercentage);
    totalCostProduct.addEventListener('change', updatePercentage);

    updatePercentage();
  }

  /** END: Raw materials automatic calculations */

  /** BEGIN: Workforce automatic calculations */

  duplicateWorkforce(target){
    const duplicateButton = target.querySelector('.js-duplicate');
    const typeJobInput = target.querySelector('input[name*="type_job_related_manufacturing"]');
    const descriptionDutiesInput = target.querySelector('input[name*="description_duties"]');
    const manufactureAddressInput = target.querySelector('input[name*="manufacture_address"]');
    const manufactureAddress2Input = target.querySelector('input[name*="manufacture_address2"]');
    const manufactureCityInput = target.querySelector('input[name*="manufacture_city"]');
    const manufacturePostalCodeInput = target.querySelector('input[name*="manufacture_postal_code"]');



    duplicateButton.onclick = function(e) {
      e.preventDefault();

      const addAssociation = document.getElementById("add_workforces");
      addAssociation.click();

      const lastChild = [...document.querySelectorAll('.workforces')].pop();
      const employeeSiteQuebecInput = target.querySelector('input[name*="employee_relate_site_quebec"]:checked');

      lastChild.querySelector('input[name*="type_job_related_manufacturing"]').value = typeJobInput.value;
      lastChild.querySelector('input[name*="description_duties"]').value = descriptionDutiesInput.value;
      lastChild.querySelector('input[name*="employee_relate_site_quebec"][value="' + employeeSiteQuebecInput?.value + '"]').checked = true;
      lastChild.querySelector('input[name*="manufacture_address"]').value = manufactureAddressInput.value;
      lastChild.querySelector('input[name*="manufacture_address2"]').value = manufactureAddress2Input.value;
      lastChild.querySelector('input[name*="manufacture_city"]').value = manufactureCityInput.value;
      lastChild.querySelector('input[name*="manufacture_postal_code"]').value = manufacturePostalCodeInput.value;
      lastChild.scrollIntoView(true);
    }
  }
  computeWorkforceUnitPrice(target) {
    const unitCostInput = target.querySelector('.js-workforce-unit-price');
    const productionTimeInput = target.querySelector('input[name*="production_time_employee_produce_one_unit_per_hour"]');
    const employeeHourlyRateInput = target.querySelector('input[name*="employee_hourly_rate"]');

    const updateUnitCost = () => {
      const value = parseNumber(productionTimeInput.value) * parseNumber(employeeHourlyRateInput.value);

      if (!isNaN(value) && value !== Infinity) {
        unitCostInput.value = formatDigitCurrency(value);
      } else {
        unitCostInput.value = formatDigitCurrency(0);
      }

      unitCostInput.dispatchEvent(new Event('change'));
    }

    productionTimeInput.addEventListener('change', updateUnitCost);
    employeeHourlyRateInput.addEventListener('change', updateUnitCost);

    updateUnitCost();
  }

  computeWorkforceUnitPriceQuebec(target) {
    const unitCostInputQuebec = target.querySelector('.js-workforce-unit-price-quebec');
    const unitPriceInput = target.querySelector('.js-workforce-unit-price');
    const employeeRelateQuebecInputs = target.querySelectorAll('input[name*="employee_relate_site_quebec"]');

    const updateUnitCost = () => {
      const selected = target.querySelector('input[name*="employee_relate_site_quebec"]:checked')?.value;

      if (selected === "true") {
        unitCostInputQuebec.value = unitPriceInput.value;
      } else {
        unitCostInputQuebec.value = formatDigitCurrency(0);
      }
      unitCostInputQuebec.dispatchEvent(new Event('change'));

    }

    employeeRelateQuebecInputs.forEach((r) => r.addEventListener('change', updateUnitCost));
    unitPriceInput.addEventListener('change', updateUnitCost);

    updateUnitCost();
  }

  computePercentageWorkforceQuebec(target) {
    const totalCostInputQuebec = target.querySelector('.js-workforce-unit-price-quebec');
    const totalCostProduct = target.querySelector('input[name*="total_workforce_cost_per_product"]');
    const percentageInput = target.querySelector('.js-workforce-quebec-percentage');

    const updateWorkforcePercentage = () => {
      const value = Math.round(parseNumber(totalCostInputQuebec.value) / parseNumber(totalCostProduct.value) * 100);

      if (!isNaN(value) && value !== Infinity) {
        percentageInput.value = `${value} %`;
      } else {
        percentageInput.value = '0 %';
      }

      percentageInput.dispatchEvent(new Event('change'));
    }

    totalCostInputQuebec.addEventListener('change', updateWorkforcePercentage);
    totalCostProduct.addEventListener('change', updateWorkforcePercentage);

    updateWorkforcePercentage();
  }

  /** END: Workforce automatic calculations */

  /** BEGIN: Child counters */
  computeWorkforcesChildCount() {
    this.computeCocoonChildCount(this.workforcesTargets);
  }

  computeRawMaterialsChildCount() {
    this.computeCocoonChildCount(this.rawMaterialsTargets);
  }

  computeCocoonChildCount(targets) {
    let count = 0;
    targets.forEach((target) => {
      if (target.querySelector('input[name*="[_destroy]"]')?.value !== "1") {
        target.querySelector('.js-child-count').textContent = count + 1;
        count++;
      }
    });
  }

  /** END: Child counters */

  /** BEGIN: Question1 automatic calculations (Blue box) */
  computeBlueBox(target) {
    const blueBoxClasses = [
      ".js-product-unit-price-quebec",
      ".js-workforce-unit-price-quebec",
      ".js-product-unit-price",
      ".js-workforce-unit-price"
    ]

    target
      .querySelectorAll(blueBoxClasses.join(",")).forEach((input) => {
      input.addEventListener('change', () => this.element.dispatchEvent(new Event("js-blue-box-update")));
    });

    this.element.dispatchEvent(new Event("js-blue-box-update"));
  }

  listenBlueBoxChange() {
    this.element.addEventListener('js-blue-box-update', () => {
      this._updateBlueBoxTotal('.js-total-unit-cost-quebec', '.js-product-unit-price-quebec');
      this._updateBlueBoxTotal('.js-total-workforce-unit-cost-quebec', '.js-workforce-unit-price-quebec');
      this._updateBlueBoxTotal('.js-total-unit-cost-product', '.js-product-unit-price');
      this._updateBlueBoxTotal('.js-total-unit-cost', '.js-workforce-unit-price');

      const unitCostQuebecPercentageEl = this.element.querySelector('.js-direct-unit-cost-quebec-percentage');

      if (unitCostQuebecPercentageEl) {
        const h = this.element.querySelector('.js-total-unit-cost-quebec');
        const f = this.element.querySelector('.js-total-workforce-unit-cost-quebec');
        const g = this.element.querySelector('.js-total-unit-cost-product');
        const e = this.element.querySelector('.js-total-unit-cost');

        unitCostQuebecPercentageEl.textContent = (() => {
          const value = (parseNumber(h.value) + parseNumber(f.value)) / (parseNumber(g.value) + parseNumber(e.value));
          if (isNaN(value) || value === Infinity) return "0 %";
          return `${Math.round(value * 100)} %`;
        })();
      }
    });

    this.element.dispatchEvent(new Event("js-blue-box-update"));
  }

  _updateBlueBoxTotal(blueBoxInputClass, inputClasses) {
    const blueBoxInput = document.querySelector(blueBoxInputClass);

    if (blueBoxInput) {
      const inputs = document.querySelectorAll(inputClasses);

      let value = 0;
      inputs.forEach((input) => {
        // if not deleted
        if (input.closest('.nested-fields').querySelector('input[name*="[_destroy]"]')?.value !== "1") {
          value += parseNumber(input.value)
        }
      });

      blueBoxInput.value = formatDigitCurrency(value);
      blueBoxInput.dispatchEvent(new Event('change'));
    }
  }

  /** END: Question1 automatic calculations */
}
