import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multifile-download"
export default class extends Controller {
  static targets = ["file"]
  run(event) {
    event.preventDefault();
    // The timeout is necessary for IE, which will otherwise only download the first file.
    this.fileTargets.forEach((link) => {
      setTimeout(function () {
        link.click();
      }, 500);
    });
  }
}

