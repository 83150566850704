import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="per-page"
export default class extends Controller {
  connect() {
    this.addListener(this.element);
  }

  addListener(select) {
    select.addEventListener('change', (e) => {
      const params = new URLSearchParams(window.location.search);
      params.set('per_page', e.target.value);

      // window.location.href = `${location.protocol}//${location.host}${location.pathname}?${params.toString()}`;
      Turbo.visit(`${location.pathname}?${params.toString()}`, { action: "replace" });
    });
  }
}
