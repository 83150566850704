import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dropdown-other"
export default class extends Controller {
  static targets = ["dropdown", "hide", "show"]

  connect() {
  }

  dropdownTargetConnected(target) {
    target.addEventListener('change', () => {
      this.updateTargetsVisibility();
    });
  }

  hideTargetConnected(target) {
    this.updateTargetsVisibility();
  }

  showTargetConnected(target) {
    this.updateTargetsVisibility();
  }

  updateTargetsVisibility() {
    if (this.isOtherSelected()) {
      if (this.hasHideTarget) {
        this.hideTarget.value = null;
        this.hideTarget.parentNode.classList.add("d-none");
      }

      if (this.hasShowTarget) {
        this.showTarget.parentNode.classList.remove("d-none");
      }
    } else {
      if (this.hasHideTarget) {
        this.hideTarget.parentNode.classList.remove("d-none");
      }

      if (this.hasShowTarget) {
        this.showTarget.value = null;
        this.showTarget.parentNode.classList.add("d-none");
      }
    }
  }

  isOtherSelected() {
    if (!this.hasDropdownTarget) return false;

    return Array.from(this.dropdownTarget.querySelectorAll("option:checked")).some((o) => o.dataset.other === "true");
  }
}
