import { Controller } from "@hotwired/stimulus"
import { resetValues } from "../../../website/javascript/utils/html"

// Connects to data-controller="questions--designed-in-quebec--question2"
export default class extends Controller {
  connect() {
    this.showDesignedLastCollaboration(this.element);
  }

  /** BEGIN: Frontend validations */
  showDesignedLastCollaboration(target) {
    const designedLastCollaborationDiv = target.querySelector('.js-designed-last-collaboration');
    const designedExternalCollaborationInputs = target.querySelectorAll('input[name*="designed_external_collaboration"]');

    const updateVisibility = () => {
      const selected = target.querySelector('input[name*="designed_external_collaboration"]:checked')?.value;

      if (selected === "true") {
        designedLastCollaborationDiv.classList.remove("d-none");
      }else {
        resetValues(designedLastCollaborationDiv);
        designedLastCollaborationDiv.classList.add("d-none");
        designedLastCollaborationDiv.dispatchEvent(new Event('change'));
      }
    }
    designedExternalCollaborationInputs.forEach((r) => r.addEventListener('change', updateVisibility));

    updateVisibility();
  }
  /** END: Frontend validations */
}
