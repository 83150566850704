import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-reload"
export default class extends Controller {
  connect() {
  }

  reloadForm(e) {
    e.target.parentNode.insertBefore(this.createHiddenFormInput('reload', 'true'), e.target.nextSibling);
    this.element.setAttribute('novalidate', 'true');
    this.element.requestSubmit();
  }

  createHiddenFormInput(name, value) {
    const newInput = document.createElement('input');
    newInput.setAttribute('value',value);
    newInput.setAttribute('name',name);
    newInput.setAttribute('style', 'display: none;');
    return newInput;
  }
}
