import { Controller } from "@hotwired/stimulus";
// Connects to data-controller="renewal-product-complete"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (e) => this.handleClick(e));
  }
  handleClick(e) {
    const checkbox = document.getElementById("accept_terms");

    if (!checkbox.checked) {
      e.preventDefault();
      return;
    }
  }
}
