// import "@oddcamp/cocoon-vanilla-js";
import "cocoon-js-vanilla";

const computeInsertIds = (insertedElement) => {
  let cocoonInsertId = insertedElement.querySelector('input').name.split('[');
  cocoonInsertId = parseInt(cocoonInsertId[cocoonInsertId.length - 2].slice(0, -1));

  insertedElement.innerHTML = insertedElement.innerHTML.replaceAll("{{cocoonInsertId}}", cocoonInsertId);
}

document.addEventListener('cocoon:after-insert', (event) => {
  const insertedElement = event.detail[2];
  computeInsertIds(insertedElement);
});

document.addEventListener('page:load', () => {
  document.querySelectorAll('.nested-fields').forEach((insertedElement) => {
    computeInsertIds(insertedElement);
  });
});