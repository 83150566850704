import {Controller} from "@hotwired/stimulus";
import Swal from "sweetalert2";

// Connects to data-controller="renewal-product-confirmation"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (e) => this.handleClick(e));
  }

  handleClick(e) {
    if (this.element.dataset.productChanged === "false") {
      e.preventDefault();

      Swal
        .mixin({
          customClass: {
            actions: "d-flex flex-column gap-2",
            popup: "d-flex flex-column align-items-center justify-content-center px-4 py-5",
            closeButton: "position-absolute end-0 top-0",
            confirmButton: "btn btn-secondary",
            cancelButton: "btn btn-grey",
            htmlContainer: "ff-barlow text-secondary fw-medium fs-4"
          },
          buttonsStyling: false
        }).fire({
        html: this.element.dataset.text,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Je confirme",
        cancelButtonText: `Annuler`
      }).then((result) => {
        if (result.isConfirmed) {
          this.element.form.requestSubmit(this.element);
        }
      });
    }
  }
}
