let locale = 'fr';

document.addEventListener('page:load', () => {
  locale = document.body.dataset.locale;
});

export const formatDigit = (number) => {
  return (Math.round(number * 100) / 100).toFixed(2);
}

export const formatDigitCurrency = (number) => {
  if (isNaN(number)) return NaN;

  return parseNumber(number).toLocaleString(`${locale}-CA`, {style: "currency", currency: "CAD"});
}

export const formatDecimal = (number) => {
  if (isNaN(number)) return NaN;

  return parseNumber(number).toLocaleString(`${locale}-CA`);
}

export const parseNumber = (str) => {
  if (typeof str === "number") return str;
  if (typeof str === "undefined") return 0.0;

  if (str.includes(".")) {
    str = str.replaceAll(',', '');
  } else {
    str = str.replaceAll(',', '.');
  }

  return parseFloat(str.replaceAll('$', '').replaceAll(/\s+/g, "")) || 0;
}