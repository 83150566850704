import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="credit-card-input"
export default class extends Controller {
  static targets = ["number", "month", "year", "cvv"];

  numberTargetConnected(target) {
    target.addEventListener('keyup', (e) => {
      if ((e.target.value || "").length === 19 && e.key !== 'Backspace') {
        this.monthTarget.focus();
      }
    });
  }

  monthTargetConnected(target) {
    target.addEventListener('keyup', (e) => {
      if ((e.target.value || "").length === 2 && e.key !== 'Backspace' ) {
        this.yearTarget.focus();
      } else if ((e.target.value || "").length === 0 && e.key === 'Backspace') {
        const end = this.numberTarget.value.length
        this.numberTarget.setSelectionRange(end, end)
        this.numberTarget.focus();
      }
    });
  }

  yearTargetConnected(target) {
    target.addEventListener('keyup', (e) => {
      if ((e.target.value || "").length === 4 && e.key !== 'Backspace') {
        this.cvvTarget.focus();
      } else if ((e.target.value || "").length === 0 && e.key === 'Backspace') {
        const end = this.monthTarget.value.length
        this.monthTarget.setSelectionRange(end, end)
        this.monthTarget.focus();
      }
    });
  }

  cvvTargetConnected(target) {
    target.addEventListener('keyup', (e) => {
      if ((e.target.value || "").length === 0 && e.key === 'Backspace') {
        const end = this.yearTarget.value.length
        this.yearTarget.setSelectionRange(end, end)
        this.yearTarget.focus();
      }
    });
  }
}