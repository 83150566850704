import TomSelect from '@remotedevforce/tom-select';

/**
 * Implement Tom-Select support with
 * 1. AJAX Support
 * 2. Multiple element support
 * 3. Dynamic element support
 */

export const loadTomSelect = (elem, ajax) => {
  const extraField = document.querySelector(elem.dataset.extraField);

  const extraFieldName = (() => {
    if (!extraField) return;
    if (!extraField.name.includes('[')) return extraField.name;

    return extraField.name.substring(extraField.name.indexOf('[') + 1, extraField.name.length - 1);
  })();

  const ajaxExtraConfig = {
    labelField: 'text',
    valueField: 'value',
    searchField: 'text',
    preload: true,
    load: (query, callback) => {
      const extraFieldParams = extraFieldName ? `&${extraFieldName}=${extraField.value}` : '';
      const url = `${elem.dataset.url}?query=${encodeURIComponent(query)}${extraFieldParams}`;
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          callback(json.items);
        })
        .catch(() => {
          callback();
        });
    },
    render: undefined,
    onChange: function (value) {
      /**
       * Add support for data attributes in the ajax payload.
       */
      Object.values(this.options).forEach((ajaxOption) => {
        // Could be duplicated because of how ts behave.
        const originalOptions = this.input.querySelectorAll(`option[value="${ajaxOption.value}"]`);
        originalOptions.forEach((originalOption) => {
          Object.keys(ajaxOption).filter((k) => k.startsWith("data-")).forEach((key) => {
            originalOption.dataset[key.replace("data-", "")] = ajaxOption[key];
          });
        });
      });

      /**
       * Remove placeholder when an option is picked up.
       */
      if (typeof value === "object") {
        if (value.length > 0) {
          if (this.settings.placeholder) {
            this.control_input.dataset.placeholderBackup = this.settings.placeholder;
            this.control_input.placeholder = "";
            this.settings.placeholder = "";
          }
        } else {
          this.control_input.placeholder = this.control_input.dataset.placeholderBackup
          this.settings.placeholder = this.control_input.dataset.placeholderBackup;
        }
      }
    }
  };

  const createConfig = {create: elem.dataset.dynamic === "true"};

  const renderOverrides = {
    item: (data, escape) => {
      const customClass = data.$option ? data.$option.classList.toString() : '';
      return `<div class="${customClass}">${escape(data.text)}</div>`;
    },
  };

  let ts;
  // Multiple or single selection
  if (elem.getAttribute('multiple')) {
    ts = new TomSelect(elem, {
      plugins: ['remove_button'],
      render: renderOverrides,
      ...createConfig,
      ...(ajax && ajaxExtraConfig)
    });
  } else {
    ts = new TomSelect(elem, {render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig)});
  }
  // Behavior improvement for multiple
  ts.on('item_add', function () {
    if (elem.multiple) {
      this.input.nextElementSibling.querySelector('input').value = '';
      this.refreshOptions();
    }
  });
  if (extraField && extraFieldName) {
    // Reload options when the extra field changes. Debouncing should be considered if it's an input.
    extraField.addEventListener('change', () => {
      // Clear selected option
      ts.clear();
      // Clear all options
      ts.clearOptions();
      // Refresh options
      ts.load('');
    });
    // Clear options on load, but not the preselected one.
    ts.clearOptions();
  }
};
